import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Accordion,
  Form,
  OverlayTrigger,
  Tooltip,
  Alert,
} from 'react-bootstrap';
import {
  BsCheckLg,
  BsDashCircleFill,
  BsFillPlusCircleFill,
} from 'react-icons/bs';
import './SelectMapsList.css';

export default function SelectMapList(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => setLoading(false), [props.maps]);

  const [search, setSearch] = useState('');
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const [showAllMaps, setShowAllMaps] = useState(false);

  const selectedCount = props.maps.filter((item) => item.selected).length;
  const maxCount = 8;

  return (
    <>
      <Accordion.Header className="d-flex align-items-center">
        <div className="d-md-flex flex-wrap align-items-center">
          <h3 className="m-md-0 me-md-2">Select Map Pool</h3>
          <div class="d-flex flex-wrap">
            {!loading &&
              props.maps
                .filter((item) => item.selected)
                .map(({ id, image_src, display_name }) => (
                  <OverlayTrigger
                    key={id}
                    placement={'top'}
                    overlay={<Tooltip>{display_name}</Tooltip>}
                  >
                    <div
                      className="me-2 avatar map-avatar"
                      style={{ backgroundImage: `url('${image_src}')` }}
                    ></div>
                  </OverlayTrigger>
                ))}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <Form.Group className="mb-3" controlId="search">
          <Form.Control
            type="text"
            placeholder="Search Maps..."
            onChange={handleChange}
            value={search}
            onFocus={({ target }) => target.select()}
          />
        </Form.Group>
        {selectedCount >= maxCount && (
          <Alert variant="primary" className="p-2">
            You reached the maximum number of maps
          </Alert>
        )}
        <section className="">
          {loading && <span>Loading</span>}
          <div>
            <h3 className="m-0">Current Map Pool</h3>
            <Row className="map_list_area">
              {!loading &&
                props.maps
                  .filter((item) => item.current_pool)
                  .filter((item) => {
                    if (search === '') {
                      return true;
                    }

                    return item.display_name
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .map(({ id, display_name, image_src, selected }) => (
                    <Col
                      md={6}
                      key={`map_${id}`}
                      className="d-flex flex-nowrap align-items-center mb-2"
                    >
                      <input
                        type="checkbox"
                        id={`map_${id}`}
                        className="map-checkbox me-2"
                        checked={selected}
                        disabled={selectedCount >= maxCount && !selected}
                        onChange={(e) =>
                          props.handleMapsChange(id, e.target.checked)
                        }
                      />
                      <label
                        htmlFor={`map_${id}`}
                        className="map-checkbox-label d-flex align-items-center w-100"
                      >
                        <div
                          className="map-checkbox-label-bg"
                          style={{ backgroundImage: `url('${image_src}')` }}
                        ></div>
                        <div className="map-checkbox-label-check">
                          {selected && <BsCheckLg />}
                        </div>
                        <span className="map-checkbox-label-name">
                          {display_name}
                        </span>
                      </label>
                    </Col>
                  ))}
            </Row>
          </div>
          <div>
            <h3 className="">
              <span className="me-3">All Maps</span>
              {showAllMaps ? (
                <BsDashCircleFill
                  role="button"
                  onClick={() => setShowAllMaps(!showAllMaps)}
                />
              ) : (
                <BsFillPlusCircleFill
                  role="button"
                  onClick={() => setShowAllMaps(!showAllMaps)}
                />
              )}
            </h3>
            <Row className="map_list_area">
              {showAllMaps &&
                !loading &&
                props.maps
                  .filter((item) => !item.current_pool)
                  .filter((item) => {
                    if (search === '') {
                      return true;
                    }

                    return item.display_name
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .map(({ id, display_name, image_src, selected }) => (
                    <Col
                      md={6}
                      key={`map_${id}`}
                      className="d-flex flex-nowrap align-items-center mb-2"
                    >
                      <input
                        type="checkbox"
                        id={`map_${id}`}
                        className="map-checkbox me-2"
                        checked={selected}
                        disabled={selectedCount >= maxCount && !selected}
                        onChange={(e) =>
                          props.handleMapsChange(id, e.target.checked)
                        }
                      />
                      <label
                        htmlFor={`map_${id}`}
                        className="map-checkbox-label d-flex align-items-center w-100"
                      >
                        <div
                          className="map-checkbox-label-bg"
                          style={{ backgroundImage: `url('${image_src}')` }}
                        ></div>
                        <div className="map-checkbox-label-check">
                          {selected && <BsCheckLg />}
                        </div>
                        <span className="map-checkbox-label-name">
                          {display_name}
                        </span>
                      </label>
                    </Col>
                  ))}
            </Row>
          </div>
        </section>
      </Accordion.Body>
    </>
  );
}
