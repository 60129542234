import React from 'react';
import './PlayerCard.css';

export default function PlayerCard(props) {
  const name = props.player.name;
  const race = props.player.race;
  //   const league = props.player.league;
  const key = props.player.key;
  return (
    <>
      <div
        className={`player-card player-${key} player-card-${
          race.toLowerCase() || 'random'
        }`}
      >
        {/* {race && <h6>{race}</h6>}
        {league && <h6>{league}</h6>} */}

        <h4 className="player-name">{name}</h4>
      </div>
    </>
  );
}
