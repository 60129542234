import React from 'react';
import { Accordion, Row, Col, Form, Badge } from 'react-bootstrap';
import './SelectFormat.css';

export default function SelectFormat(props) {
  const handleBestOfChange = ({ target }) => {
    props.handleBestOfChange(target.value);
  };

  const handleFormatChange = ({ target }) => {
    props.handleFormatChange(target.value);
  };
  return (
    <>
      <Accordion.Header className="d-flex align-items-center">
        <div className="d-md-flex flex-wrap align-items-center">
          <h3 className="m-md-0 me-md-2">Set Format</h3>
          <Badge className="me-2" bg="primary">
            {props.bestOf.display}
          </Badge>
          <Badge className="me-2" bg="primary">
            {props.format}
          </Badge>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <Row className="g-2">
          <Form.Group as={Col} controlId="bo">
            <Form.Label>Select Best Of:</Form.Label>
            <Form.Select onChange={handleBestOfChange} value={props.bestOf.key}>
              {props.bestOfList.map((item) => (
                <option key={`bo_${item.key}`} value={item.key}>
                  {item.display}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="format">
            <Form.Label>Select Format:</Form.Label>
            <Form.Select onChange={handleFormatChange} value={props.format}>
              {props.formatList.map((item, index) => (
                <option key={`format_${index}`} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </Accordion.Body>
    </>
  );
}
