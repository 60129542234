import _16_Bit_LE from './images/16-Bit_LE.jpg';
import _2000_Atmospheres from './images/2000_Atmospheres_LE.jpg';
import Abiogenesis_LE from './images/Abiogenesis_LE.jpg';
import Abyssal_Reef_LE from './images/Abyssal_Reef_LE.jpg';
import Acid_Plant_LE from './images/Acid_Plant_LE.jpg';
import Acolyte_LE from './images/Acolyte_LE.jpg';
import Acropolis_SC2_Map from './images/Acropolis_LE.jpg';
import Apotheosis from './images/Apotheosis_LE.jpg';
import Ascension_to_Aiur_LE from './images/Ascension_to_Aiur_LE.jpg';
import Automaton_Map from './images/Automaton_LE.jpg';
import Backwater_LE from './images/Backwater_LE.jpg';
import Battle_on_the_Boardwalk_LE from './images/Battle_on_the_Boardwalk_LE.jpg';
import Beckett_Industries from './images/Beckett_Industries_LE.jpg';
import BelShir_Vestige_LE from './images/BelShir_Vestige_LE.jpg';
import Berlingrad from './images/Berlingrad_LE.jpg';
import Blackburn from './images/Blackburn_LE.jpg';
import Blackpink_LE from './images/Blackpink_LE.jpg';
import Blood_Boil_LE from './images/Blood_Boil_LE.jpg';
import Blueshift_LE from './images/Blueshift_LE.jpg';
import Bridgehead_LE from './images/Bridgehead_LE.jpg';
import CactusValley from './images/Cactus_Valley.jpg';
import Catallena from './images/Catallena.jpg';
import Catalyst_LE from './images/Catalyst_LE.jpg';
import Central_Protocol from './images/Central_Protocol.jpg';
import Cerulean_Fall_LE from './images/Cerulean_Fall_LE.jpg';
import Coda_LE from './images/Coda.jpg';
import Curious_Minds from './images/Curious_Minds_LE.jpg';
import Cyber_Forest_Map from './images/Cyber_Forest_LE.jpg';
import Darkness_Sanctuary from './images/Darkness_Sanctuary_LE.jpg';
import Dasan_Station_LE from './images/Dasan_Station_LE.jpg';
import Dash_and_Terminal_LE from './images/Dash_and_Terminal_LE.jpg';
import Daybreak from './images/Daybreak.jpg';
import Deathaura from './images/Deathaura_LE.jpg';
import Defenders_Landing_LE from './images/Defenders_Landing_LE.jpg';
import Disco_Bloodbath from './images/Disco_Bloodbath_LE.jpg';
import Dreamcatcher_Map from './images/Dreamcatcher_LE.jpg';
import Dusk_Towers from './images/Dusk_Towers.jpg';
import Eastwatch_LE from './images/Eastwatch_LE.jpg';
import Echo_LE from './images/Echo_LE.jpg';
import Ephemeron from './images/Ephemeron_LE.jpg';
import Eternal_Empire from './images/Eternal_Empire_LE.jpg';
import Ever_Dream from './images/Ever_Dream_LE.jpg';
import Fracture_LE from './images/Fracture_LE.jpg';
import Frost_LE from './images/Frost_LE.jpg';
import Frozen_Temple from './images/Frozen_Temple.jpg';
import Galactic_Process_LE from './images/Galactic_Process_LE.jpg';
import Glittering_Ashes_LE from './images/Glittering_Ashes_LE.jpg';
import Golden_Wall_LE from './images/Golden_Wall_LE.jpg';
import Habitation_Station from './images/Habitation_Station.jpg';
import Hardwire from './images/Hardwire_LE.jpg';
import Honorgrounds_LE from './images/Honorgrounds_LE.jpg';
import Ice_and_Chrome from './images/Ice_and_Chrome_LE.jpg';
import Inferno_Pools from './images/Inferno_Pools.jpg';
import Interloper_LE from './images/Interloper_LE.jpg';
import Invader from './images/Invader_LE.jpg';
import Iron_Fortress from './images/Iron_Fortress.jpg';
import Jagannatha from './images/Jagannatha_LE.jpg';
import Kairos_Junction_LE from './images/Kairos_Junction_LE.jpg';
import Kings_Cove_LE from './images/Kings_Cove_LE.jpg';
import Korhal_Carnage_Knockout from './images/Korhal_Carnage_Knockout.jpg';
import Lerilak_Crest from './images/Lerilak_Crest.jpg';
import Lightshade from './images/Lightshade_LE.jpg';
import Lost_and_Found_LE from './images/Lost_and_Found_LE.jpg';
import Mech_Depot_LE from './images/Mech_Depot_LE.jpg';
import Moonlight_Madness_LE from './images/Moonlight_Madness_LE.jpg';
import Neon_Violet_Square_LE from './images/Neon_Violet_Square_LE.jpg';
import New_Gettysburg_LE from './images/New_Gettysburg_LE.jpg';
import New_Repugnancy_LE from './images/New_Repugnancy_LE.jpg';
import Newkirk_Precinct_TE from './images/Newkirk_Precinct_TE.jpg';
import Nightshade_LE from './images/Nightshade_LE.jpg';
import Odyssey_LE from './images/Odyssey_LE.jpg';
import Orbital_Shipyard from './images/Orbital_Shipyard.jpg';
import Overgrowth from './images/Overgrowth.jpg';
import Oxide_LE from './images/Oxide_LE.jpg';
import Paladino_Terminal_LE from './images/Paladino_Terminal_LE.jpg';
import Para_Site_LE from './images/Para_Site_LE.jpg';
import Pillars_of_Gold_LE from './images/Pillars_of_Gold_LE.jpg';
import Port_Aleksander_LE from './images/Port_Aleksander_LE.jpg';
import Pride_of_Altaris_LE from './images/Pride_of_Altaris_LE.jpg';
import Prion_Terraces from './images/Prion_Terraces.jpg';
import Proxima_Station_LE from './images/Proxima_Station_LE.jpg';
import Purity_and_Industry_LE from './images/Purity_and_Industry_LE.jpg';
import Redshift_LE from './images/Redshift_LE.jpg';
import Romanticide from './images/Romanticide_LE.jpg';
import Ruins_of_Endion from './images/Ruins_of_Endion.jpg';
import Ruins_of_Seras from './images/Ruins_of_Seras.jpg';
import Sequencer_LE from './images/Sequencer_LE.jpg';
import Simulacrum from './images/Simulacrum_LE.jpg';
import Stasis_LE from './images/Stasis_LE.jpg';
import Submarine from './images/Submarine_LE.jpg';
import Terraform_LE from './images/Terraform_LE.jpg';
import The_King_Sejong_Station_LE from './images/The_King_Sejong_Station_LE.jpg';
import Thunderbird from './images/Thunderbird_LE.jpg';
import Triton from './images/Triton_LE.jpg';
import Turbo_Cruise_84 from './images/Turbo_Cruise_84_LE.jpg';
import Ulrena from './images/Ulrena.jpg';
import Vaani_Research_Station from './images/Vaani_Research_Station.jpg';
import Whirlwind from './images/Whirlwind.jpg';
import Winters_Gate from './images/Winters_Gate_LE.jpg';
import World_of_Sleepers_LE from './images/World_of_Sleepers_LE.jpg';
import Year_Zero_LE from './images/Year_Zero_LE.jpg';
import Zen from './images/Zen_LE.jpg';

export default function fakeData() {
  return {
    current_pool: [
      '2000 Atmospheres LE',
      'Berlingrad LE',
      'Beckett Industries LE',
      'Blackburn LE',
      'Curious Minds LE',
      'Glittering Ashes LE',
      'Hardwire LE',
      'Pride of Altaris LE',
    ],
    maps: [
      {
        display_name: '16-Bit LE',
        image_src: _16_Bit_LE,
      },
      {
        display_name: '2000 Atmospheres LE',
        image_src: _2000_Atmospheres,
      },
      {
        display_name: 'Abiogenesis LE',
        image_src: Abiogenesis_LE,
      },
      {
        display_name: 'Abyssal Reef LE',
        image_src: Abyssal_Reef_LE,
      },
      {
        display_name: 'Acid Plant LE',
        image_src: Acid_Plant_LE,
      },
      {
        display_name: 'Acolyte LE',
        image_src: Acolyte_LE,
      },
      {
        display_name: 'Acropolis LE',
        image_src: Acropolis_SC2_Map,
      },
      {
        display_name: 'Apotheosis LE',
        image_src: Apotheosis,
      },
      {
        display_name: 'Ascension to Aiur LE',
        image_src: Ascension_to_Aiur_LE,
      },
      {
        display_name: 'Automaton LE',
        image_src: Automaton_Map,
      },
      {
        display_name: 'Backwater LE',
        image_src: Backwater_LE,
      },
      {
        display_name: 'Battle on the Boardwalk LE',
        image_src: Battle_on_the_Boardwalk_LE,
      },
      {
        display_name: 'Beckett Industries LE',
        image_src: Beckett_Industries,
      },
      {
        display_name: "Bel'Shir Vestige",
        image_src: BelShir_Vestige_LE,
      },
      {
        display_name: 'Berlingrad LE',
        image_src: Berlingrad,
      },
      {
        display_name: 'Blackburn LE',
        image_src: Blackburn,
      },
      {
        display_name: 'Blackpink LE',
        image_src: Blackpink_LE,
      },
      {
        display_name: 'Blood Boil LE',
        image_src: Blood_Boil_LE,
      },
      {
        display_name: 'Blueshift LE',
        image_src: Blueshift_LE,
      },
      {
        display_name: 'Bridgehead',
        image_src: Bridgehead_LE,
      },
      {
        display_name: 'Cactus Valley',
        image_src: CactusValley,
      },
      {
        display_name: 'Catallena',
        image_src: Catallena,
      },
      {
        display_name: 'Catalyst LE',
        image_src: Catalyst_LE,
      },
      {
        display_name: 'Central Protocol',
        image_src: Central_Protocol,
      },
      {
        display_name: 'Cerulean Fall LE',
        image_src: Cerulean_Fall_LE,
      },
      {
        display_name: 'Coda',
        image_src: Coda_LE,
      },
      {
        display_name: 'Curious Minds LE',
        image_src: Curious_Minds,
      },
      {
        display_name: 'Cyber Forest LE',
        image_src: Cyber_Forest_Map,
      },
      {
        display_name: 'Darkness Sanctuary LE',
        image_src: Darkness_Sanctuary,
      },
      {
        display_name: 'Dasan Station LE',
        image_src: Dasan_Station_LE,
      },
      {
        display_name: 'Dash and Terminal',
        image_src: Dash_and_Terminal_LE,
      },
      {
        display_name: 'Daybreak',
        image_src: Daybreak,
      },
      {
        display_name: 'Deathaura LE',
        image_src: Deathaura,
      },
      {
        display_name: "Defender's Landing LE",
        image_src: Defenders_Landing_LE,
      },
      {
        display_name: 'Disco Bloodbath LE',
        image_src: Disco_Bloodbath,
      },
      {
        display_name: 'Dreamcatcher LE',
        image_src: Dreamcatcher_Map,
      },
      {
        display_name: 'Dusk Towers',
        image_src: Dusk_Towers,
      },
      {
        display_name: 'Eastwatch LE',
        image_src: Eastwatch_LE,
      },
      {
        display_name: 'Echo',
        image_src: Echo_LE,
      },
      {
        display_name: 'Ephemeron LE',
        image_src: Ephemeron,
      },
      {
        display_name: 'Eternal Empire LE',
        image_src: Eternal_Empire,
      },
      {
        display_name: 'Ever Dream LE',
        image_src: Ever_Dream,
      },
      {
        display_name: 'Fracture LE',
        image_src: Fracture_LE,
      },
      {
        display_name: 'Frost',
        image_src: Frost_LE,
      },
      {
        display_name: 'Frozen Temple',
        image_src: Frozen_Temple,
      },
      {
        display_name: 'Galactic Process LE',
        image_src: Galactic_Process_LE,
      },
      {
        display_name: 'Glittering Ashes LE',
        image_src: Glittering_Ashes_LE,
      },
      {
        display_name: 'Golden Wall LE',
        image_src: Golden_Wall_LE,
      },
      {
        display_name: 'Habitation Station',
        image_src: Habitation_Station,
      },
      {
        display_name: 'Hardwire LE',
        image_src: Hardwire,
      },
      {
        display_name: 'Honorgrounds LE',
        image_src: Honorgrounds_LE,
      },
      {
        display_name: 'Ice and Chrome LE',
        image_src: Ice_and_Chrome,
      },
      {
        display_name: 'Inferno Pools',
        image_src: Inferno_Pools,
      },
      {
        display_name: 'Interloper LE',
        image_src: Interloper_LE,
      },
      {
        display_name: 'Invader LE',
        image_src: Invader,
      },
      {
        display_name: 'Iron Fortress',
        image_src: Iron_Fortress,
      },
      {
        display_name: 'Jagannatha LE',
        image_src: Jagannatha,
      },
      {
        display_name: 'Kairos Junction LE',
        image_src: Kairos_Junction_LE,
      },
      {
        display_name: "King's Cove LE",
        image_src: Kings_Cove_LE,
      },
      {
        display_name: 'Korhal Carnage Knockout',
        image_src: Korhal_Carnage_Knockout,
      },
      {
        display_name: 'Lerilak Crest',
        image_src: Lerilak_Crest,
      },
      {
        display_name: 'Lightshade LE',
        image_src: Lightshade,
      },
      {
        display_name: 'Lost and Found LE',
        image_src: Lost_and_Found_LE,
      },
      {
        display_name: 'Mech Depot LE',
        image_src: Mech_Depot_LE,
      },
      {
        display_name: 'Moonlight Madness',
        image_src: Moonlight_Madness_LE,
      },
      {
        display_name: 'Neon Violet Square LE',
        image_src: Neon_Violet_Square_LE,
      },
      {
        display_name: 'New Gettysburg LE',
        image_src: New_Gettysburg_LE,
      },
      {
        display_name: 'New Repugnancy LE',
        image_src: New_Repugnancy_LE,
      },
      {
        display_name: 'Newkirk Precinct',
        image_src: Newkirk_Precinct_TE,
      },
      {
        display_name: 'Nightshade LE',
        image_src: Nightshade_LE,
      },
      {
        display_name: 'Odyssey LE',
        image_src: Odyssey_LE,
      },
      {
        display_name: 'Orbital Shipyard',
        image_src: Orbital_Shipyard,
      },
      {
        display_name: 'Overgrowth',
        image_src: Overgrowth,
      },
      {
        display_name: 'Oxide LE',
        image_src: Oxide_LE,
      },
      {
        display_name: 'Paladino Terminal LE',
        image_src: Paladino_Terminal_LE,
      },
      {
        display_name: 'Para Site LE',
        image_src: Para_Site_LE,
      },
      {
        display_name: 'Pillars of Gold LE',
        image_src: Pillars_of_Gold_LE,
      },
      {
        display_name: 'Port Aleksander LE',
        image_src: Port_Aleksander_LE,
      },
      {
        display_name: 'Pride of Altaris LE',
        image_src: Pride_of_Altaris_LE,
      },
      {
        display_name: 'Prion Terraces',
        image_src: Prion_Terraces,
      },
      {
        display_name: 'Proxima Station LE',
        image_src: Proxima_Station_LE,
      },
      {
        display_name: 'Purity and Industry LE',
        image_src: Purity_and_Industry_LE,
      },
      {
        display_name: 'Redshift LE',
        image_src: Redshift_LE,
      },
      {
        display_name: 'Romanticide LE',
        image_src: Romanticide,
      },
      {
        display_name: 'Ruins of Endion',
        image_src: Ruins_of_Endion,
      },
      {
        display_name: 'Ruins of Seras',
        image_src: Ruins_of_Seras,
      },
      {
        display_name: 'Sequencer LE',
        image_src: Sequencer_LE,
      },
      {
        display_name: 'Simulacrum LE',
        image_src: Simulacrum,
      },
      {
        display_name: 'Stasis LE',
        image_src: Stasis_LE,
      },
      {
        display_name: 'Submarine LE',
        image_src: Submarine,
      },
      {
        display_name: 'Terraform',
        image_src: Terraform_LE,
      },
      {
        display_name: 'The King Sejong Station',
        image_src: The_King_Sejong_Station_LE,
      },
      {
        display_name: 'Thunderbird LE',
        image_src: Thunderbird,
      },
      {
        display_name: 'Triton LE',
        image_src: Triton,
      },
      {
        display_name: "Turbo Cruise '84 LE",
        image_src: Turbo_Cruise_84,
      },
      {
        display_name: 'Ulrena',
        image_src: Ulrena,
      },
      {
        display_name: 'Vaani Research Station',
        image_src: Vaani_Research_Station,
      },
      {
        display_name: 'Whirlwind',
        image_src: Whirlwind,
      },
      {
        display_name: "Winter's Gate LE",
        image_src: Winters_Gate,
      },
      {
        display_name: 'World of Sleepers LE',
        image_src: World_of_Sleepers_LE,
      },
      {
        display_name: 'Year Zero LE',
        image_src: Year_Zero_LE,
      },
      {
        display_name: 'Zen LE',
        image_src: Zen,
      },
    ],
  };
}

export const getBestOfList = (mapsLength = 5) => {
  let list = [
    {
      key: 'bo1',
      display: 'Best of 1',
    },
  ];

  if (mapsLength > 2) {
    list.push({
      key: 'bo3',
      display: 'Best of 3',
    });
  }

  if (mapsLength > 4) {
    list.push({
      key: 'bo5',
      display: 'Best of 5',
    });
  }

  if (mapsLength > 6) {
    list.push({
      key: 'bo7',
      display: 'Best of 7',
    });
  }

  return list;
};

// TODO: Need to figure out a more progamatic approach to this
export const getFormatList = (bestOfKey = 'bo1', mapsLength = 5) => {
  switch (bestOfKey) {
    case 'bo1':
      if (mapsLength === 1) return ['C'];
      if (mapsLength === 2) return ['A C'];
      if (mapsLength === 3) return ['AB C'];
      if (mapsLength === 4) return ['ABA C'];
      if (mapsLength === 5) return ['ABAB C', 'ABBA C'];
      if (mapsLength === 6) return ['ABABA C', 'ABBAB C'];
      if (mapsLength === 7) return ['ABABAB C', 'ABBAAB C', 'ABAABB C'];
      if (mapsLength === 8) return ['ABABABA C', 'ABBAABA C', 'ABAABBA C'];
      break;
    case 'bo3':
      if (mapsLength === 3) return ['ABC'];
      if (mapsLength === 4) return ['A BAC'];
      if (mapsLength === 5) return ['AB ABC'];
      if (mapsLength === 6) return ['ABA BAC', 'ABB ABC'];
      if (mapsLength === 7) return ['ABAB ABC', 'ABBA BAC'];
      if (mapsLength === 8) return ['ABABA BAC', 'ABBAA BAC'];
      break;
    case 'bo5':
      if (mapsLength === 5) return ['ABABC', 'ABBAC'];
      if (mapsLength === 6) return ['A BABAC', 'A BBAAC'];
      if (mapsLength === 7)
        return ['AB ABABC', 'AB BABAC', 'AB ABBAC', 'AB BAABC'];
      if (mapsLength === 8)
        return ['ABA BABAC', 'ABA BAABC', 'ABB ABABC', 'ABB ABBAC'];
      break;
    case 'bo7':
      if (mapsLength === 7) return ['ABABABC', 'ABBAABC'];
      if (mapsLength === 8) return ['A BABABAC', 'A BBAABAC'];
      break;
    default:
      // do nothing
      break;
  }

  return [];
  // throw 'getFormatList broke!';
};
