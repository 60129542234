import React from 'react';
import { Row, Col, Badge, Accordion } from 'react-bootstrap';
// import { BsArrowLeftRight } from 'react-icons/bs';
import './PlayerSetup.css';
import PlayerForm from '../PlayerForm/PlayerForm';

export default function PlayerSetup(props) {
  return (
    <>
      <Accordion.Header className="d-flex align-items-center">
        <div className="d-md-flex flex-wrap align-items-center">
          <h3 className="m-md-0 me-md-2">Setup Player</h3>
          <Badge className="me-2" bg="primary">
            {props.playerA.name}{' '}
            {props.playerA.league && `, ${props.playerA.league}`}{' '}
            {props.playerA.race && `, ${props.playerA.race}`}
          </Badge>
          <Badge className="me-2" bg="primary">
            {props.playerB.name}{' '}
            {props.playerB.league && `, ${props.playerB.league}`}{' '}
            {props.playerB.race && `, ${props.playerB.race}`}
          </Badge>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <Row className="justify-content-center">
          <Col md={4}>
            <PlayerForm
              handleChange={props.handleChange}
              player={props.playerA}
            />
          </Col>
          {/* <Col
            md={1}
            className="align-self-center d-flex justify-content-center"
          >
            <BsArrowLeftRight  />
          </Col> */}
          <Col md={{ span: 4, offset: 1 }}>
            <PlayerForm
              handleChange={props.handleChange}
              player={props.playerB}
            />
          </Col>
        </Row>
      </Accordion.Body>
    </>
  );
}
