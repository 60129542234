import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Container, Button, Accordion } from 'react-bootstrap';
import fakeData from '../../fakeData';
import PlayerSetup from '../../Components/PlayerSetup/PlayerSetup';
import SelectMapsList from '../../Components/SelectMaps/SelectMapsList';
import SelectFormat from '../../Components/SelectFormat/SelectFormat';
import VetoModal from '../../Components/VetoModal/VetoModal';

export default function Setup(props) {
  useEffect(() => {
    loadMaps();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMaps = async () => {
    // Fake Fetch Promise
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(fakeData());
      }, 0);
    }).then((response) => {
      const maps = response.maps;
      const currentPool = response.current_pool;
      for (let i = 0; i < maps.length; i++) {
        const item = maps[i];
        if (currentPool.includes(item.display_name)) {
          item.selected = true;
          item.current_pool = true;
        }
        item.id = i;
      }
      props.setMaps(maps);
    });
  };

  const [modalShow, setModalShow] = useState(false);

  const onSubmit = () => {
    setModalShow(false);
  };

  return (
    <>
      <Container fluid="md" className="py-5">
        <Accordion defaultActiveKey="0" className="mb-3">
          <Accordion.Item eventKey="0">
            <PlayerSetup
              playerA={props.playerA}
              handleChange={props.handlePlayerChange}
              playerB={props.playerB}
            />
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <SelectMapsList
              maps={props.maps}
              handleMapsChange={props.handleMapsChange}
            />
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <SelectFormat
              bestOf={props.bestOf}
              bestOfList={props.bestOfList}
              handleBestOfChange={props.handleBestOfChange}
              format={props.format}
              formatList={props.formatList}
              handleFormatChange={props.handleFormatChange}
            />
          </Accordion.Item>
        </Accordion>

        <div className="d-flex flex-column flex-md-row justify-content-md-center">
          <Button
            variant="primary"
            size="lg"
            className="me-2 px-5"
            onClick={() => {
              props.resetPicks();
              setModalShow(true);
            }}
          >
            Start Vetos!
          </Button>
          {props.picks.length > 0 && (
            <Button
              as={Link}
              to="/summary"
              variant="primary"
              className="px-5 ms-md-3"
              size="lg"
            >
              Summary
            </Button>
          )}
        </div>
      </Container>

      <VetoModal
        playerA={props.playerA}
        playerB={props.playerB}
        maps={props.maps}
        picks={props.picks}
        addPick={props.addPick}
        resetPicks={props.resetPicks}
        bestOf={props.bestOf}
        format={props.format}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={onSubmit}
      />
    </>
  );
}
