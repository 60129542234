import { Card } from 'react-bootstrap';

export default function Info() {
  return (
    <>
      <div className="d-flex flex-column p-5">
        <Card className="mb-3">
          <Card.Header>Map Vetoes</Card.Header>
          <Card.Body>
            A simple Starcraft 2 map veto system created to make player vetoes
            less painful.
          </Card.Body>
        </Card>
        <Card className="">
          <Card.Header>Legal Stuff</Card.Header>
          <Card.Body>
            <h5>Blizzard Entertainment®</h5>
            <p>
              Some content/images are owned by Blizzard and are used according
              to{' '}
              <a
                href="https://www.blizzard.com/en-us/legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.blizzard.com/en-us/legal
              </a>
            </p>
            <p>
              Blizzard Entertainment is a trademark or registered trademark of
              Blizzard Entertainment, Inc. in the U.S. and/or other countries.
              All rights reserved.
            </p>
            <h5>Liquipedia</h5>
            <p>
              Map and names content pulled from{' '}
              <a
                href="https://liquipedia.net/starcraft2/Maps/Ladder_Maps/Legacy_of_the_Void"
                target="_blank"
                rel="noopener noreferrer"
              >
                Liquiepia.com
              </a>
            </p>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
