import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

import './App.css';
import { Nav, Button } from 'react-bootstrap';
import Setup from '../../Pages/Setup/Setup';
import Summary from '../../Pages/Summary/Summary';
import Info from '../../Components/Info/Info';
import { getBestOfList, getFormatList } from '../../fakeData';

function App() {
  // Setup Players
  const [playerA, setPlayerA] = useState({
    name: 'Player A',
    key: 'a',
    race: '',
    league: '',
  });
  const [playerB, setPlayerB] = useState({
    name: 'Player B',
    key: 'b',
    race: '',
    league: '',
  });
  const handlePlayerChange = (field, value) => {
    const player = field.split('_')[1];
    const type = field.split('_')[2];

    // Update Player State
    if (player === 'a') {
      setPlayerA((prevState) => {
        return { ...prevState, [type]: value };
      });
    } else {
      setPlayerB((prevState) => {
        return { ...prevState, [type]: value };
      });
    }
  };

  const [maps, setMaps] = useState([]);
  const handleMapsChange = (key, isChecked) => {
    setMaps((prevState) =>
      prevState.map((item) => {
        if (item.id === key) {
          item.selected = isChecked;
        }

        return item;
      })
    );
  };

  // Setup bestOfList
  const [bestOfList, setBestOfList] = useState([]);

  // Set bestOfList/bestOf state after maps updates
  useEffect(() => {
    const mapsLength = maps.filter((map) => map.selected).length;
    const _bestOfList = getBestOfList(mapsLength);
    setBestOfList(_bestOfList);
    setBestOf(_bestOfList[0]);
  }, [maps]);

  // Setup BestOf
  const [bestOf, setBestOf] = useState({});
  const handleBestOfChange = (key) => {
    const mapsLength = maps.filter((map) => map.selected).length;
    const selected = getBestOfList(mapsLength).find((item) => item.key === key);
    setBestOf(selected);
    setFormatList(getFormatList(key, mapsLength));
    setFormat(getFormatList(key)[0]); // reset format after updating best of
  };

  const [formatList, setFormatList] = useState([]);
  const [format, setFormat] = useState('');
  const handleFormatChange = (value) => {
    setFormat(value);
  };

  // Set formatList/format state after best of updates
  useEffect(() => {
    const currentBestOfKey = bestOf.key;
    const mapsLength = maps.filter((map) => map.selected).length;
    const _formatList = getFormatList(currentBestOfKey, mapsLength);
    setFormatList(_formatList);
    setFormat(_formatList[0]);
  }, [bestOf, maps]);

  const [picks, setPicks] = useState([]);
  const addPick = (mapPick) => {
    setPicks((prevState) => [...prevState, mapPick]);
  };
  const resetPicks = () => {
    setPicks([]);
  };

  return (
    <>
      <Nav defaultActiveKey="/">
        <NavLink className="nav-link" to="/">
          Setup
        </NavLink>
        <NavLink className="nav-link" to="/summary">
          Summary
        </NavLink>
        <NavLink className="nav-link" to="/info">
          Info
        </NavLink>
      </Nav>
      <Routes>
        <Route
          path="/"
          element={
            <Setup
              playerA={playerA}
              playerB={playerB}
              handlePlayerChange={handlePlayerChange}
              maps={maps}
              setMaps={setMaps}
              handleMapsChange={handleMapsChange}
              bestOfList={bestOfList}
              setBestOfList={setBestOfList}
              bestOf={bestOf}
              setBestOf={setBestOf}
              handleBestOfChange={handleBestOfChange}
              format={format}
              formatList={formatList}
              handleFormatChange={handleFormatChange}
              picks={picks}
              setPicks={setPicks}
              addPick={addPick}
              resetPicks={resetPicks}
            />
          }
        />
        <Route
          path="summary"
          element={
            <Summary
              picks={picks}
              playerA={playerA}
              playerB={playerB}
              bestOf={bestOf}
            />
          }
        />
        <Route path="info" element={<Info />} />
      </Routes>

      <Button
        variant="link"
        href="https://forms.gle/Wwsg1R9WW1ePoiV76"
        target="_blank"
        className="feedback"
        size="sm"
      >
        Feedback
      </Button>
    </>
  );
}

export default App;
