// import './App.css';
import { Link } from 'react-router-dom';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import PlayerCard from '../../Components/PlayerCard/PlayerCard';

import './Summary.css';

export default function Summary(props) {
  if (!props.picks.length) {
    return (
      <>
        <Container fluid="md" className="py-5">
          <Card className="mb-5">
            <Card.Header className="d-flex align-items-center">
              <h1>No Summary Available</h1>
            </Card.Header>
            <Card.Body>Make sure to fill out setup page</Card.Body>
          </Card>
          <div className="d-flex justify-content-center">
            <Button
              as={Link}
              to="/"
              variant="primary"
              size="lg"
              className="px-5"
            >
              Go to Setup
            </Button>
          </div>
        </Container>
      </>
    );
  }
  return (
    <>
      <Container fluid="md" className="py-5">
        <Card className="mb-5">
          <Card.Header className="d-flex justify-content-center align-items-center">
            <h1>
              {props.playerA.name} vs {props.playerB.name} /{' '}
              {props.bestOf.display}
            </h1>
          </Card.Header>
          <Card.Body>
            <Row className="py-5 justify-content-center align-items-center">
              <Col lg={3} className="d-none d-lg-block">
                <PlayerCard player={props.playerA} />
              </Col>
              <Col lg={4} className="d-flex flex-column align-items-center">
                {props.picks
                  .filter((item) => item.pick)
                  .map((item) => (
                    <div
                      className={`picks mb-4 ${
                        item.player === 'A'
                          ? 'pick-a'
                          : item.player === 'B'
                          ? 'pick-b'
                          : ''
                      }`}
                      key={`${item.order}_pick`}
                    >
                      <h5 className="m-0">Set {item.order + 1}</h5>
                      <h3 className="m-0 font-weight-bold">
                        {item.display_name}
                      </h3>
                    </div>
                  ))}
              </Col>
              <Col lg={3} className="d-none d-lg-block">
                <PlayerCard player={props.playerB} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-center">
          <Button as={Link} to="/" variant="primary" size="lg" className="px-5">
            Go to Setup
          </Button>
        </div>
      </Container>
    </>
  );
}
