import React, { useEffect, useState } from 'react';
import { Button, Badge } from 'react-bootstrap';
import './PickMapsList.css';

export default function PickMapsList(props) {
  const getPickedMaps = () => {
    return props.maps
      .filter((item) => item.selected)
      .map((item) => ({ ...item, selected: false }));
  };
  const [pickedMaps, setPickedMaps] = useState(getPickedMaps());
  const resetPicks = () => {
    setPickedMaps(getPickedMaps());
    props.resetPicks();
  };

  const format = props.format.split(' ');
  let pickOrder = [];
  if (format.length === 1) {
    for (let i = 0, picks = format[0]; i < picks.length; i++) {
      const pick = picks[i];
      pickOrder.push({ player: pick, pick: true, order: i });
    }
  } else {
    for (let i = 0, vetoes = format[0]; i < vetoes.length; i++) {
      const veto = vetoes[i];
      pickOrder.push({ player: veto, veto: true, order: i });
    }
    for (let i = 0, picks = format[1]; i < picks.length; i++) {
      const pick = picks[i];
      pickOrder.push({ player: pick, pick: true, order: i });
    }
  }

  // const [picks, setPicks] = useState([]);
  const [currentPick, setCurrentPick] = useState('');

  const handlePick = (index) => {
    const picksIndex = props.picks.length;
    const map = pickedMaps[index];

    if (map.selected) return;

    map.selected = true;

    props.addPick(Object.assign(map, pickOrder[picksIndex]));
  };

  useEffect(() => {
    if (props.picks.length + 1 === pickOrder.length) {
      const lastPickIndex = pickedMaps.findIndex((item) => !item.selected);
      handlePick(lastPickIndex);
    }

    const playerKey = pickOrder[props.picks.length]?.player;
    const playerName = props[`player${playerKey}`]?.name || '';

    setCurrentPick(playerName);
  }, [props.picks]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentPickDOM = () => {
    if (currentPick) {
      return (
        <>
          Current Pick:
          <Badge className="ms-3" bg="primary">
            {currentPick}
          </Badge>
        </>
      );
    }

    return 'Finished!';
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h5 className="text-center color-light-gold d-flex align-items-center">
          {getCurrentPickDOM()}
        </h5>

        <Button
          size="sm"
          variant="secondary"
          className="ms-auto"
          onClick={resetPicks}
        >
          Reset
        </Button>
      </div>
      <div className="picked-grid">
        {pickedMaps.map(
          (
            {
              id,
              display_name,
              image_src,
              selected,
              veto,
              pick,
              player,
              order,
            },
            index
          ) => (
            <div key={`div_picked_map_${index}`}>
              <input
                type="checkbox"
                id={`picked_map_${id}`}
                className="pick-checkbox me-2"
                checked={selected}
                onChange={() => handlePick(index)}
              />
              <label
                htmlFor={`picked_map_${id}`}
                className={`pick-checkbox-label p-2 d-flex align-items-center w-100 ${
                  veto ? 'veto' : ''
                } ${pick ? 'pick' : ''}`}
                style={{ backgroundImage: `url('${image_src}')` }}
              >
                <span className="pick-checkbox-label-name">{display_name}</span>
                {player && <span className="player">{player}</span>}
                {order && <span className="order">{order}</span>}
              </label>
            </div>
          )
        )}
      </div>
    </>
  );
}
