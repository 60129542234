import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Badge } from 'react-bootstrap';
import './VetoModal.css';
import PickMapsList from '../PickMapsList/PickMapsList';

export default function VetoModal(props) {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    setIsDone(true);
  }, [props.picks, props.maps]);

  // useEffect(() => {
  //   console.log('isDone:', isDone);
  // }, [isDone]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-start justify-content-sm-center">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex flex-column flex-sm-row"
        >
          Start Vetoes
          <div>
            <Badge bg="secondary" className="ms-sm-3">
              {props.bestOf.display}
            </Badge>
            <Badge bg="secondary" className="ms-3">
              {props.format}
            </Badge>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PickMapsList
          playerA={props.playerA}
          playerB={props.playerB}
          picks={props.picks}
          addPick={props.addPick}
          resetPicks={props.resetPicks}
          bestOf={props.bestOf}
          format={props.format}
          maps={props.maps}
        />
      </Modal.Body>
      <Modal.Footer className="d-sm-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
        <Button
          variant="secondary"
          className="me-sm-3 px-5"
          onClick={props.onHide}
        >
          Cancel
        </Button>

        <Button
          as={Link}
          to="/summary"
          variant="primary"
          className="px-5"
          onClick={props.onSubmit}
          disabled={!isDone}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
