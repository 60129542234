import React from 'react';
import { Form } from 'react-bootstrap';

export default function PlayerForm(props) {
  const handleChange = ({ target }) => {
    props.handleChange(target.id, target.value);
  };

  const raceOptions = ['Terran', 'Zerg', 'Protoss', 'Random'];
  // const leagueOptions = [
  //   'GM',
  //   'Masters',
  //   'Diamond',
  //   'Platinum',
  //   'Gold',
  //   'Silver',
  //   'Bronze',
  //   'Unranked',
  // ];

  return (
    <>
      <h4>
        Player <span className="text-capitalize">{props.player.key}</span>
      </h4>
      <Form.Group
        className="mb-3"
        controlId={`player_${props.player.key}_name`}
      >
        <Form.Label>Name:</Form.Label>
        <Form.Control
          type="text"
          placeholder="Player Name"
          value={props.player.name}
          onChange={handleChange}
          onFocus={({ target }) => target.select()}
        />
      </Form.Group>

      {/* <Form.Group
        className="mb-3"
        controlId={`player_${props.player.key}_league`}
        onChange={handleChange}
      >
        <Form.Label>League:</Form.Label>
        <Form.Select defaultValue={props.player.league}>
          <option value="">Choose League</option>
          {leagueOptions.map((item) => (
            <option
              key={`player_${props.player.key}_league_${item}`}
              value={item}
            >
              {item}
            </option>
          ))}
        </Form.Select>
      </Form.Group> */}

      <Form.Group
        className="mb-3"
        controlId={`player_${props.player.key}_race`}
      >
        <Form.Label>Race:</Form.Label>
        <Form.Select onChange={handleChange} value={props.player.race}>
          <option value="">Choose Race</option>
          {raceOptions.map((item) => (
            <option
              key={`player_${props.player.key}_race_${item}`}
              value={item}
            >
              {item}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
}
